import React, { useState, useEffect } from "react"
import { withStyles } from "@material-ui/core/styles"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import Alert from "@material-ui/lab/Alert"
import { Link, navigate } from "gatsby"
import * as Sentry from "@sentry/gatsby"
import { db } from "../../configs/firebase"
import { functions } from "../../configs/firebase"
import { MdAccountCircle } from "react-icons/md"
import { reportToSentry } from ".."
import { statuses } from "../auth"
import { statuses as authStatuses } from "../../hooks/auth"
import { steps } from "../../components/verify-block"
import { useAppState } from "../../components/provider"
import { veriffStatuses } from "./verify-identity"
import Layout from "../../components/layout"
import LoaderBlock from "../../components/loader"
import * as Web3Utils from "web3-utils"

const PrimaryRadio = withStyles({
  root: {
    "&$checked": {
      color: "#2ac4d0",
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />)

const Refund = () => {
  const {
    auth: { status: authStatus, user, profile = {} },
  } = useAppState()
  const { buyback } = profile
  const {
    veriffStatus,
    ppAcceptedAt,
    tosAcceptedAt,
    address: buybackAddress,
    isAvailable,
  } = buyback || {}
  const { text, url, title } = steps(buyback || {})
  const [last, setLastStep] = useState(false)
  const [address, setCurrentAddress] = useState("")
  const [customAddress, setCustomAddress] = useState("")
  const [customError, setCustomError] = useState(false)
  const [status, setStatus] = useState(statuses.processing)
  const [wallets, setWallets] = useState([])

  useEffect(() => {
    if (authStatus === authStatuses.loggedIn) {
      const addressDoc = db
        .collection("profiles")
        .doc(user.uid)
        .collection("addresses")
      addressDoc
        .get()
        .then(snapshot => {
          const list = []
          snapshot.forEach(doc => {
            list.push({ id: doc.id, ...doc.data() })
          })
          setWallets(list)
          setStatus(statuses.initial)
        })
        .catch(error => {
          setStatus(statuses.failed)
          reportToSentry(error, {
            user: { id: user.uid },
          })
        })
    }
  }, [authStatus, user])

  const onChange = event => {
    if (customError) {
      setCustomError(false)
    }
    setCurrentAddress(event.target.value)
  }

  const onRefundTextChange = event => {
    const { value } = event.target
    setCurrentAddress(value)
    setCustomAddress(value)
    if (Web3Utils.isAddress(value)) {
      if (customError) {
        setCustomError(false)
      }
    } else {
      setCustomError(true)
    }
  }

  const onRefundTextFocus = event => {
    const { value } = event.target
    setCurrentAddress(value)
    setCustomAddress(value)
    if (value === "") return

    if (Web3Utils.isAddress(value)) {
      if (customError) {
        setCustomError(false)
      }
    } else {
      setCustomError(true)
    }
  }

  const onSubmit = event => {
    event.preventDefault()
    setLastStep(true)
  }

  const onChangeAddress = () => {
    setLastStep(false)
  }

  const onFinalise = event => {
    event.preventDefault()
    const postRefund = functions.httpsCallable("buyback-setAddress")
    setStatus(statuses.processing)
    postRefund({ address })
      .then(() => {
        setStatus(statuses.success)
        navigate("/buyback/guide")
      })
      .catch(err => {
        setStatus(statuses.failed)
        reportToSentry(err, {
          user: { id: user.uid },
        })
      })
  }

  if (user === null && authStatus === authStatuses.initial) {
    navigate("/")
    return null
  }

  if (
    authStatus === authStatuses.loggedIn &&
    !(veriffStatus === veriffStatuses.approved && ppAcceptedAt && tosAcceptedAt)
  ) {
    navigate("/profile")
    return null
  }
  if (!isAvailable && typeof window !== "undefined") {
    navigate("/profile")
    return null
  }

  let form
  if (last) {
    form = (
      <form className="Form" onSubmit={onFinalise}>
        <p className="Text centered">
          I confirm the address below belongs to me and I'm willing to receive
          USDT (ERC20 token) to it.
        </p>
        <p className="Lead dark">
          <strong>{address}</strong>
        </p>
        {status === statuses.failed && (
          <Alert severity="error" style={{ marginBottom: "1rem" }}>
            Something went wrong&hellip; Please try submitting it again later.
          </Alert>
        )}

        <div className="Text centered">
          <button
            className="Btn outline spaces"
            type="button"
            onClick={onChangeAddress}
            disabled={status === statuses.processing}
          >
            Change address
          </button>
          <button
            className="Btn primary spaces"
            type="submit"
            disabled={status === statuses.processing}
          >
            Continue
          </button>
        </div>
      </form>
    )
  } else {
    form = (
      <form className="Form" onSubmit={onSubmit}>
        {wallets.length ? (
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="refund addresses"
              name="refund"
              onChange={onChange}
              value={address}
            >
              <>
                {wallets.map(({ id }) => (
                  <FormControlLabel
                    className="RadioItem"
                    control={<PrimaryRadio />}
                    key={id}
                    label={id}
                    value={id}
                  />
                ))}
                <FormControlLabel
                  className="RadioItem widen"
                  control={<PrimaryRadio />}
                  label={
                    <div
                      className={`Textfield widen lg${
                        customError ? " error" : ""
                      }`}
                    >
                      <input
                        className="input"
                        name="refund"
                        onChange={onRefundTextChange}
                        onFocus={onRefundTextFocus}
                        placeholder="Enter your ETH address"
                        type="text"
                      />
                    </div>
                  }
                  value={customAddress}
                />
                {customError && (
                  <Alert severity="error" style={{ marginBottom: "1rem" }}>
                    Enter a valid Ethereum address.
                  </Alert>
                )}
              </>
            </RadioGroup>
          </FormControl>
        ) : (
          <div style={{ height: "15rem" }}>
            <LoaderBlock isLoading />
          </div>
        )}
        <br />
        <button
          className="Btn primary"
          type="submit"
          disabled={customError || !address || status === statuses.processing}
        >
          Continue
        </button>
      </form>
    )
  }

  return (
    <Layout>
      <Sentry.ErrorBoundary
        beforeCapture={scope => {
          scope.setUser({ id: profile.uid })
        }}
      >
        <div className="Section">
          <div className="PanelContainer fixedTop">
            <h1 className="Title">USDT Withdrawal Address</h1>
            <section className="Panel formFormat">
              <p className="Text">
                Please provide us with an ETH address where you wish to receive
                a payment for your MSP tokens. The payment will be done in USDT
                (ERC20), please make sure your wallet supports it. You can
                receive it either to one of the addresses you provided earlier,
                or provide us with another address.
              </p>
              <hr />
              {status === statuses.success || buybackAddress ? (
                <div className="Text centered">
                  <MdAccountCircle size="7em" className="Icon success" />
                  <p className="Text">
                    You're going to receive your your refund at
                  </p>
                  <p className="Lead dark">
                    <strong>{buybackAddress}</strong>
                  </p>
                  <Link className="Btn primary lg" to={url} title={title}>
                    {text}
                  </Link>
                </div>
              ) : (
                form
              )}
            </section>
          </div>
        </div>
      </Sentry.ErrorBoundary>
    </Layout>
  )
}

export default Refund
