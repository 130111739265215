import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { veriffStatuses } from "../pages/buyback/verify-identity"
import { MdAccountCircle } from "react-icons/md"
import CircularProgress from "@material-ui/core/CircularProgress"

export const steps = ({
  address,
  ppAcceptedAt,
  tosAcceptedAt,
  veriffStatus,
}) => {
  if (!ppAcceptedAt)
    return {
      text: "Proceed with the Buyback",
      title: "Privacy Policy",
      url: "/buyback/privacy-policy",
    }

  if (!tosAcceptedAt)
    return {
      text: "Proceed with the Buyback",
      title: "Terms of Service",
      url: "/buyback/terms-of-service",
    }

  if (veriffStatus !== veriffStatuses.approved)
    return {
      text: "Proceed with the Buyback",
      title: "Identity Verification",
      url: "/buyback/verify-identity",
    }

  if (!address)
    return {
      text: "Continue with the Buyback",
      title: "Continue with the Buyback",
      url: "/buyback/refund-address",
    }

  return {
    text: "Receive funds",
    title: "Buyback Procedure",
    url: "/buyback/guide",
  }
}

const VerifyBlock = ({ buyback }) => {
  const { veriffStatus, address, isAvailable } = buyback
  if (!isAvailable) return null
  const { text, url, title } = steps(buyback)

  switch (veriffStatus) {
    case veriffStatuses.approved:
      if (address) {
        return (
          <section className="Panel formFormat">
            <div className="Text centered">
              <p className="Text">
                Thank you for providing us with all your information regarding
                the buyback. We prepared a step-by-step guide for you.
              </p>
              <Link className="Btn primary lg" to={url} title={title}>
                {text}
              </Link>
            </div>
          </section>
        )
      }
      return (
        <section className="Panel formFormat">
          <div className="Text centered">
            <MdAccountCircle size="7em" className="Icon success" />
            <p className="Text">You identity is verified.</p>
            <Link className="Btn primary lg" to={url} title={title}>
              {text}
            </Link>
          </div>
        </section>
      )
    case veriffStatuses.declined:
      return (
        <section className="Panel formFormat">
          <div className="Text centered">
            <MdAccountCircle size="7em" className="Icon failed" />
            <p className="Text">
              Your verification got rejected by the verification system.
            </p>
            <p className="Text">
              We're investigating the case and will get back to you. In
              meanwhile you can contact us on Intercom or write us an email to{" "}
              <a href="mailto:hello@mothership.cx">hello@mothership.cx</a>
            </p>
          </div>
        </section>
      )
    case veriffStatuses.resubmission:
      return (
        <section className="Panel formFormat">
          <div className="Text centered">
            <div className="Progress">
              <MdAccountCircle size="7em" className="Icon failed" />
            </div>
            <p className="Text">
              The verification system didn't recognize the information on photos
              you provided. Please, give it another try. If you keep
              experiencing this issue, please contact us on Intercom or write an
              email to{" "}
              <a href="mailto:hello@mothership.cx">hello@mothership.cx</a>
            </p>
            <Link className="Btn primary lg" to={url} title={title}>
              Resubmit documents
            </Link>
          </div>
        </section>
      )
    case veriffStatuses.submitted:
      return (
        <section className="Panel formFormat">
          <div className="Text centered">
            <div className="Progress">
              <CircularProgress size="5rem" color="inherit" />
            </div>
            <p className="Text">
              The identification platform is processing your data. Usually it
              takes up to 5 minutes.
            </p>
            <p className="Text">
              We'll keep you posted once we got a result. If you didn't receive
              the result in an hour, please contact us on Intercom.
            </p>
          </div>
        </section>
      )
    default:
  }

  return (
    <section className="Panel formFormat">
      <p className="Text centered">
        You can sell all of your MSP tokens back to Mothership.
      </p>
      <div className="Text centered">
        <Link className="Btn primary lg" to={url} title={title}>
          {text}
        </Link>
      </div>
    </section>
  )
}

VerifyBlock.propTypes = {
  buyback: PropTypes.shape({}).isRequired,
}

export default VerifyBlock
